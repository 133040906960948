@keyframes drift {
    from {
        transform: rotate(0deg);
    }
    from {
        transform: rotate(360deg);
    }
}
@keyframes wobbling_x {
    0% { transform:translateX( -7px); }
  100% { transform:translateX(  0px); }
}
@keyframes wobbling_y {
    0% { transform:translateY(-10px); }
  100% { transform:translateY(  0px); }
}
@keyframes wobbling_bx {
    0% { transform:translateX( -10px); }
  100% { transform:translateX(  0px); }
}
@keyframes wobbling_by {
    0% { transform:translateY(-15px); }
  100% { transform:translateY(  0px); }
}
@keyframes anim_sc {
    100% { transform: scale(1, 1); }
}